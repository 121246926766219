module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"مدونة بندر","short_name":"مدونة بندر","start_url":"/","background_color":"#F1E8C6","theme_color":"#F1E8C6","display":"standalone","icon":"./static/favicon.svg","icons":[{"src":"/favicons/144x144.png","sizes":"144x144","type":"image/png","purpose":"any maskable"},{"src":"/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png","purpose":"maskable"},{"src":"/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png","purpose":"maskable"},{"src":"/favicons/apple-touch-icon.png","sizes":"180x180","type":"image/png","purpose":"maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c0a1e29992cfef91b11113d4e2d99a7e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-page-progress/gatsby-browser.js'),
      options: {"plugins":[],"includePaths":["/",{"regex":"^/blog"}],"excludePaths":["/contact","/about","/tags"],"height":4,"prependToBody":false,"color":"var(--textcolor)","footerHeight":500},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","enableListener":true,"preconnect":["https://fonts.gstatic.com"],"web":[{"name":["Markazi Text","Lalezar"],"file":"https://fonts.googleapis.com/css?family=Lalezar|Markazi+Text:400,600,700&display=swap&subset=arabic"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-123778774-2","head":true,"anonymize":true,"respectDNT":true,"pageTransitionDelay":0,"exclude":[],"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/about/","/contact/","/blog/*"],"runtimeCaching":[{"urlPattern":{},"handler":"cacheFirst","options":{"cacheableResponse":{"statuses":[0,200]},"cacheName":"google-fonts-webfonts","expiration":{"maxAgeSeconds":3600,"maxEntries":30}}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://bandar.design"},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
