import React, { useState } from "react"
import { FaSearch } from "@react-icons/all-files/fa/FaSearch"
import { AiOutlineCloseCircle } from "@react-icons/all-files/ai/AiOutlineCloseCircle"
import algoliasearch from "algoliasearch/lite"
import { InstantSearch, SearchBox } from "react-instantsearch-dom"
import searchPreloader from "../images/search_preloader.gif"
import CustomStateResults from "./CustomStateResults"

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const Search = () => {
  const [showResult, setShowResult] = useState(false)

  return (
    <>
      <div className="search-bg">
        <div className="search-wrapper">
          <InstantSearch searchClient={searchClient} indexName="Blog">
            <SearchBox
              className="search-field"
              showLoadingIndicator
              submit={<FaSearch className="search-icons" />}
              reset={<AiOutlineCloseCircle className="search-icons" />}
              translations={{
                placeholder: "ابحث عن مقال",
              }}
              onChange={(event) => {
                if (event.currentTarget.value === "") {
                  setShowResult(false)
                } else {
                  setShowResult(true)
                }
              }}
              onReset={(event) => {
                setShowResult(false)
              }}
              loadingIndicator={
                <img
                  src={searchPreloader}
                  style={{
                    width: "20px",
                    height: "20px",
                    position: "absolute",
                    top: "30px",
                    left: "0",
                  }}
                  alt="Loading.."
                />
              }
            />
            {showResult && <CustomStateResults />}
          </InstantSearch>
        </div>
      </div>
    </>
  )
}

export default Search
