import React, { useContext } from "react"
import { FaSearch } from "@react-icons/all-files/fa/FaSearch"
import { GlobalDispatchContext } from "../context/GlobalContextProvider"

const SearchToggle = () => {
  const dispatch = useContext(GlobalDispatchContext)

  return (
    <>
      <div className="search-toggle-wrapper search-icon">
        <FaSearch
          onClick={() => {
            dispatch({ type: "TOGGLE_MODAL" })
          }}
        />
      </div>
    </>
  )
}

export default SearchToggle
