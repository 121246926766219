import React from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"

const Layout = ({ children }) => {
  return (
    <main dir="rtl">
      <Header />

      <div className="layout-content-wrapper">
        <section className="section pt-0">
          <div className="container">
            <> {children}</>
          </div>
        </section>
      </div>

      <Footer />
    </main>
  )
}

export default Layout
