import React, { createContext, useReducer } from "react"

export const GlobalStateContext = createContext()
export const GlobalDispatchContext = createContext()

function reducer(state, action) {
  switch (action.type) {
    case "TOGGLE_MODAL":
      document.body.classList.toggle("noscroll")
      return {
        ...state,
        toggleModal: state.toggleModal === false ? true : false,
      }

    case "TOGGLE_NOTIFICATION":
      return {
        ...state,
        toggleNotification: state.toggleNotification === false ? true : false,
      }

    default:
      return
  }
}

const GlobalContextProvider = ({ children }) => {
  const initialState = {
    toggleModal: false,
    toggleNotification: false,
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
