import React from "react"
import "./src/style/css/main.scss"
import GlobalContextProvider from "./src/context/GlobalContextProvider"

// Global Context

export const wrapRootElement = ({ element }) => {
  return <GlobalContextProvider>{element}</GlobalContextProvider>
}

export const onInitialClientRender = () => {
  setTimeout(function () {
    document.getElementById("___loader").style.display = "none"
    document.body.classList.remove("noscroll")
  }, 1000)
}
