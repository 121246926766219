import React from "react"
import { AiOutlineTwitter } from "@react-icons/all-files/ai/AiOutlineTwitter"
import { FaBehance } from "@react-icons/all-files/fa/FaBehance"
import { FaLinkedinIn } from "@react-icons/all-files/fa/FaLinkedinIn"
import { FaQuora } from "@react-icons/all-files/fa/FaQuora"
import { useStaticQuery, graphql } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const getSiteMeta = graphql`
  query {
    site {
      siteMetadata {
        social {
          behance
          dribbble
          instagram
          linkedIn
          twitter
          quora
        }
      }
    }
  }
`

const SocialIcons = () => {
  const { site } = useStaticQuery(getSiteMeta)
  const { social } = site.siteMetadata

  return (
    <>
      <div className="icons-wrapper">
        <OutboundLink
          href={social.quora}
          title="Quora"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaQuora />
        </OutboundLink>

        <OutboundLink
          href={social.twitter}
          title="Twitter"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiOutlineTwitter />
        </OutboundLink>
        <OutboundLink
          href={social.linkedIn}
          title="LinkedIn"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaLinkedinIn />
        </OutboundLink>

        <OutboundLink
          href={social.behance}
          title="Behance"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaBehance />
        </OutboundLink>

        {/* 
        <OutboundLink
          href={social.dribbble}
          title="Dribbble"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiOutlineDribbble />
        </OutboundLink>

        <OutboundLink
          href={social.instagram}
          title="Instagram"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram />
        </OutboundLink> */}
      </div>
    </>
  )
}

export default SocialIcons
