import React, { useContext, useState } from "react"
import { AiOutlineCloseCircle } from "@react-icons/all-files/ai/AiOutlineCloseCircle"
import PropTypes from "prop-types"
import { GlobalDispatchContext } from "../context/GlobalContextProvider"

const Modal = ({ children }) => {
  const dispatch = useContext(GlobalDispatchContext)
  const [slideOut, setSlideOut] = useState(false)

  return (
    <div className={slideOut ? "modal-bg slide-right-out" : "modal-bg"}>
      <AiOutlineCloseCircle
        className="close-modal"
        onClick={() => {
          setSlideOut(true)
          setTimeout(function () {
            dispatch({ type: "TOGGLE_MODAL" })
          }, 300)
        }}
      />
      {children}
    </div>
  )
}

Modal.propTypes = {
  children: PropTypes.element.isRequired,
}

export default Modal
