import React from "react"
import logoImage from "../images/logo.svg"
import { Link } from "gatsby"

const Logo = () => {
  return (
    <>
      <Link to="/" className="logo-link">
        <img
          src={logoImage}
          alt="logo"
          className="logo"
          width="400"
          height="400"
        />
      </Link>
    </>
  )
}

export default Logo
