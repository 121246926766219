import React, { useContext } from "react"
import Logo from "./Logo"
import SearchToggle from "./SearchToggle"
import Menu from "./Menu"
import Modal from "./Modal"
import Search from "./Search"
import ThemeToggle from "./ThemeToggle"
import { GlobalStateContext } from "../context/GlobalContextProvider"

const Header = () => {
  const state = useContext(GlobalStateContext)

  return (
    <>
      {state.toggleModal && (
        <Modal>
          <Search />
        </Modal>
      )}

      <header id="top" className="header-wrapper">
        <div
          className="container"
          style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
        >
          <div className="theme-toggle-wrapper">
            <ThemeToggle />
          </div>
        </div>

        <div
          className="container"
          style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
        >
          <div className="columns">
            <div className="column theme-wrapper">
              <Logo />
            </div>
            <div className="column menu-wrapper">
              <SearchToggle />
              <Menu />
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header
