import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { globalHistory as history } from "@reach/router"

const Menu = () => {
  const { location } = history
  const activeLink = "active-link"
  const links = { blog: "/blog/", about: "/about/", contact: "/contact/" }
  const [linksState, setLinksState] = useState({
    blog: true,
    about: false,
    contact: false,
  })
  const allLinksState = ["blogState", "aboutState", "contactState"]

  const { pathname } = location

  useEffect(() => {
    if (location !== "undefined") {
      const values = Object.values(links)
      for (let i = 0; i < values.length; i = i + 1) {
        if (pathname.includes(values[i])) {
          if (allLinksState[i] === "blogState") {
            setLinksState({
              blog: true,
              about: false,
              contact: false,
            })
          } else if (allLinksState[i] === "aboutState") {
            setLinksState({
              blog: false,
              about: true,
              contact: false,
            })
          } else if (allLinksState[i] === "contactState") {
            setLinksState({
              blog: false,
              about: false,
              contact: true,
            })
          }
        }
      }
    }

    if (location.pathname === "/") {
      setLinksState({
        blog: true,
        about: false,
        contact: false,
      })
    }
  }, [linksState, allLinksState, location, pathname])

  return (
    <>
      <nav>
        <ul>
          <li className="list-wrapper">
            <Link
              // className="active-link"
              className={linksState.blog ? activeLink : null}
              activeClassName={activeLink}
              partiallyActive={true}
              to={links.blog}
              onClick={(e) => {
                setLinksState({
                  blog: true,
                  about: false,
                  contact: false,
                })
                trackCustomEvent({
                  category: "المقالات",
                  action: "Click",
                  label: "Click on posts page link at the menu",
                })
              }}
            >
              مقالات
            </Link>
          </li>
          <li className="list-wrapper">
            <Link
              activeClassName={activeLink}
              className={linksState.about ? activeLink : null}
              to={links.about}
              partiallyActive={true}
              onClick={(e) => {
                setLinksState({
                  blog: false,
                  about: true,
                  contact: false,
                })
                trackCustomEvent({
                  category: "عني",
                  action: "Click",
                  label: "Click on about page link at the menu",
                })
              }}
            >
              عني
            </Link>
          </li>
          <li className="list-wrapper">
            <Link
              activeClassName={activeLink}
              className={linksState.contact ? activeLink : null}
              to={links.contact}
              partiallyActive={true}
              onClick={(e) => {
                setLinksState({
                  blog: false,
                  about: false,
                  contact: true,
                })
                trackCustomEvent({
                  category: "راسلني",
                  action: "Click",
                  label: "Click on contact page link at the menu",
                })
              }}
            >
              راسلني
            </Link>
          </li>
        </ul>
      </nav>
    </>
  )
}

export default Menu
