import React from "react"
import Menu from "./Menu"
import SocialIcons from "./SocialIcons"
import { IoIosArrowDropup } from "@react-icons/all-files/io/IoIosArrowDropup"

import scrollTo from "gatsby-plugin-smoothscroll"

const Footer = () => {
  return (
    <div style={{ position: "relative" }}>
      <a
        className="toups"
        href="#top"
        onClick={(e) => {
          e.preventDefault()
          scrollTo("#top")
        }}
        aria-label="To the top"
      >
        <IoIosArrowDropup className="top-arrow" />
      </a>

      <footer className="footer">
        <div>
          <div className="columns footer-container">
            <div className="column menu-wrapper">
              <Menu />
            </div>
            <div className="column social-wrapper">
              <SocialIcons />
              <p className="copyrights">© 2020 Bandar Design</p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer
