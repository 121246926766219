import React, { useContext } from "react"
import { Link } from "gatsby"
import { GlobalDispatchContext } from "../context/GlobalContextProvider"
import { connectStateResults } from "react-instantsearch-dom"
import PropTypes from "prop-types"

import { useStaticQuery, graphql } from "gatsby"

const getSiteMeta = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const StateResults = ({ searchResults }) => {
  const hasResults = searchResults && searchResults.nbHits !== 0
  const dispatch = useContext(GlobalDispatchContext)
  const { site } = useStaticQuery(getSiteMeta)
  const { siteUrl } = site.siteMetadata

  return (
    <>
      <div className="results-wrapper">
        {hasResults ? (
          searchResults.hits.map((item, index) => {
            return (
              <Link
                key={index}
                to={`${siteUrl}/blog/${item.slug}/`}
                onClick={() => {
                  dispatch({ type: "TOGGLE_MODAL" })
                }}
              >
                {item.title}
              </Link>
            )
          })
        ) : (
          <p className="no-results">لا توجد نتائج</p>
        )}
      </div>
    </>
  )
}

StateResults.propTypes = {
  searchResults: PropTypes.object,
  hasResults: PropTypes.bool,
  DataArray: PropTypes.string,
}

const CustomStateResults = connectStateResults(StateResults)

export default CustomStateResults
