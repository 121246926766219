import React, { useEffect, useState } from "react"
import useDarkMode from "use-dark-mode"

const ThemeToggle = () => {
  const darkMode = useDarkMode(undefined, {
    classNameDark: "dark--theme",
    classNameLight: "default--theme",
  })
  const [checkboxState, setCheckboxState] = useState(false)
  useEffect(() => {
    setCheckboxState(darkMode.value)
  }, [])
  return (
    <>
      <div className="toggle-wrapper">
        <label
          htmlFor="theme"
          style={{ marginRight: "-10000px", color: "white" }}
        >
          change theme mode
        </label>
        <input
          id="theme"
          name="theme"
          onChange={() => {
            darkMode.toggle()
            setCheckboxState(!darkMode.value)
          }}
          type="checkbox"
          checked={checkboxState}
        />

        <span></span>
      </div>
    </>
  )
}

export default ThemeToggle
